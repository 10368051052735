import { Decimal } from "decimal.js";
import { z } from "zod";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";
import { DealDelegationNew, dealDelegationCreateSchema } from "./deal-delegation";
import { TokenBasedGrantNew, UsdBasedGrantNew, dealGrantCreateSchema } from "./deal-grant";

export const partnerDealStatusSchema = z.enum(["RED", "YELLOW", "GREEN", "CREATED"]);
export type PartnerDealStatus = z.infer<typeof partnerDealStatusSchema>;

export type PartnerDeal = {
  id: string;
  partnerId: string;
  delegationId: string | null;
  grantId: string | null;
  totalEstValueUsd: Decimal;
  status: PartnerDealStatus;
  createdById: string;
  createdAt: Date;
  updatedById: string | null;
  updatedAt: Date;
  startDate?: Date;
  endDate?: Date;
};

export type PartnerDealNew = Identity<
  Omit<
    PartnerDeal,
    | "id"
    | "createdAt"
    | "updatedAt"
    | "updatedById"
    | "grantId"
    | "delegationId"
    | "totalEstValueUsd"
    | "status"
    | "active"
  > & {
    delegation?: Omit<DealDelegationNew, "createdById" | "partnerDealId">;
    grant?:
      | (Omit<UsdBasedGrantNew, "createdById" | "partnerDealId"> & { grantType: "USD" })
      | (Omit<TokenBasedGrantNew, "createdById" | "partnerDealId"> & { grantType: "TOKEN" });
    status?: PartnerDealStatus;
    active?: boolean;
  }
>;
export type PartnerDealUpdate = Identity<
  Partial<Omit<PartnerDeal, "createdAt" | "createdById" | "partnerId" | "updatedAt" | "id">> & {
    id: string;
  }
>;

export const partnerDealSchema = z.object({
  id: z.string().uuid(),
  partnerId: z.string().uuid(),
  delegationId: z.string().uuid().optional().nullable().default(null),
  grantId: z.string().uuid().optional().nullable().default(null),
  totalEstValueUsd: z.instanceof(Decimal),
  status: partnerDealStatusSchema,
  createdById: z.string().uuid(),
  createdAt: z.date(),
  updatedById: z.string().uuid().nullable().optional().default(null),
  updatedAt: z.date().optional().default(new Date()),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  active: z.boolean().optional().default(true),
}) satisfies ZodSchema<PartnerDeal>;

export const partnerDealCreateSchema = partnerDealSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    updatedById: true,
    totalEstValueUsd: true,
    delegationId: true,
    grantId: true,
  })
  .extend({
    delegation: dealDelegationCreateSchema.optional(),
    grant: dealGrantCreateSchema.optional(),
    status: partnerDealStatusSchema.optional().default("CREATED"),
  }) satisfies ZodSchema<PartnerDealNew>;

export const partnerDealUpdateSchema = partnerDealSchema
  .partial()
  .extend({
    id: z.string().uuid(),
  })
  .omit({
    createdAt: true,
    createdById: true,
  }) satisfies ZodSchema<PartnerDealUpdate>;
