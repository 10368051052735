import { Decimal } from "decimal.js";
import { z } from "zod";
import { ZodSchema } from "../utils/zod";

export type GrantDailyArchive = {
  id: string;
  grantId: string;
  tokenPriceCurrent: Decimal | null;
  tokenAmountCurrent: Decimal | null;
  usdGrantedCurrent: Decimal | null;
  estValueUsd: Decimal;
  createdAt: Date;
  dateOfCreation: string;
  dailyArchiveId: string;
};

export type GrantDailyArchiveNew = Omit<GrantDailyArchive, "id" | "createdAt">;

export const grantDailyArchiveSchema = z.object({
  id: z.string().uuid(),
  grantId: z.string().uuid(),
  tokenPriceCurrent: z.instanceof(Decimal).nullable().optional().default(null),
  tokenAmountCurrent: z.instanceof(Decimal).nullable().optional().default(null),
  estValueUsd: z.instanceof(Decimal),
  usdGrantedCurrent: z.instanceof(Decimal).nullable().optional().default(null),
  createdAt: z.date(),
  dateOfCreation: z.string(),
  dailyArchiveId: z.string().uuid(),
  delegationId: z.string().uuid(),
  aprCurrent: z.instanceof(Decimal).nullable().optional().default(null),
  validatorCommissionCurrent: z.instanceof(Decimal).nullable().optional().default(null),
}) satisfies ZodSchema<GrantDailyArchive>;

export const grantDailyArchiveCreateSchema = grantDailyArchiveSchema.omit({
  id: true,
  createdAt: true,
}) satisfies ZodSchema<GrantDailyArchiveNew>;
