import { Decimal } from "decimal.js";
import { z } from "zod";
import { ZodSchema } from "../utils/zod";

export type DelegationDailyArchive = {
  id: string;
  delegationId: string;
  aprCurrent: Decimal | null;
  validatorCommissionCurrent: Decimal | null;
  tokenPriceCurrent: Decimal;
  tokenAmountCurrent: Decimal;
  estValueUsd: Decimal;
  createdAt: Date;
  dateOfCreation: string;
  dailyArchiveId: string;
};

export type DelegationDailyArchiveNew = Omit<DelegationDailyArchive, "id" | "createdAt">;

export const delegationDailyArchiveSchema = z.object({
  id: z.string().uuid(),
  delegationId: z.string().uuid(),
  aprCurrent: z.instanceof(Decimal).nullable(),
  validatorCommissionCurrent: z.instanceof(Decimal).nullable(),
  tokenPriceCurrent: z.instanceof(Decimal),
  tokenAmountCurrent: z.instanceof(Decimal),
  estValueUsd: z.instanceof(Decimal),
  createdAt: z.date(),
  dateOfCreation: z.string(),
  dailyArchiveId: z.string().uuid(),
}) satisfies ZodSchema<DelegationDailyArchive>;

export const delegationDailyArchiveCreateSchema = delegationDailyArchiveSchema.omit({
  id: true,
  createdAt: true,
}) satisfies ZodSchema<DelegationDailyArchiveNew>;
