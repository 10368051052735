import { Decimal } from "decimal.js";
import { z } from "zod";
import { ZodSchema } from "../utils/zod";
import { delegationDailyArchiveSchema } from "./delegation-daily-archive";
import { grantDailyArchiveSchema } from "./grant-daily-archive";

export type PartnerDealDailyArchive = {
  delegation: {
    id: string;
    delegationId: string;
    aprCurrent: Decimal | null;
    validatorCommissionCurrent: Decimal | null;
    tokenPriceCurrent: Decimal | null;
    tokenAmountCurrent: Decimal | null;
    estValueUsd: Decimal;
    createdAt: Date;
    dateOfCreation: string;
    dailyArchiveId: string;
  } | null;
  grant: {
    id: string;
    grantId: string;
    tokenPriceCurrent: Decimal | null;
    tokenAmountCurrent: Decimal | null;
    estValueUsd: Decimal;
    createdAt: Date;
    dateOfCreation: string;
    dailyArchiveId: string;
  } | null;
  id: string;
  dealId: string;
  createdAt: Date;
  dateOfCreation: string;
};

export type PartnerDealDailyArchiveNew = Omit<PartnerDealDailyArchive, "id" | "createdAt">;

export const partnerDealDailyArchiveSchema = z.object({
  id: z.string().uuid(),
  dealId: z.string().uuid(),
  createdAt: z.date(),
  dateOfCreation: z.string(),
  delegation: delegationDailyArchiveSchema.nullable(),
  grant: grantDailyArchiveSchema.nullable(),
}) satisfies ZodSchema<PartnerDealDailyArchive>;

export const partnerDealDailyArchiveCreateSchema = partnerDealDailyArchiveSchema.omit({
  id: true,
  createdAt: true,
}) satisfies ZodSchema<PartnerDealDailyArchiveNew>;
