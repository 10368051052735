import { Decimal } from "decimal.js";
import { z } from "zod";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";

export type DealUpdateEvent = {
  id: string;
  tokenAmountNew: Decimal | null;
  tokenAmountOld: Decimal | null;
  aprNew: Decimal | null;
  aprOld: Decimal | null;
  validatorCommissionNew: Decimal | null;
  validatorCommissionOld: Decimal | null;
  tokenPriceNew: Decimal | null;
  tokenPriceOld: Decimal | null;
  usdGrantedNew: Decimal | null;
  usdGrantedOld: Decimal | null;
  updateNote: string | null;
  startDate: Date | null;
  endDate: Date | null;
  maxMonths: number | null;
  createdById: string;
  delegationId: string | null;
  grantId: string | null;
  createdAt: Date;
  grantSwitchStaticRevenue: boolean | null;
  active: boolean | null;
};

export type DealUpdateEventNew = Identity<
  Omit<DealUpdateEvent, "id" | "createdAt" | "tokenPriceOld" | "aprOld" | "validatorCommissionOld" | "tokenAmountOld">
>;

export type DealUpdateEventUpdate = Identity<Partial<Omit<DealUpdateEvent, "id" | "createdAt">>>;

export const dealUpdateEventSchema = z.object({
  id: z.string().uuid(),
  tokenAmountNew: z.instanceof(Decimal).nullable(),
  tokenAmountOld: z.instanceof(Decimal).nullable(),
  aprNew: z.instanceof(Decimal).nullable(),
  aprOld: z.instanceof(Decimal).nullable(),
  validatorCommissionNew: z.instanceof(Decimal).nullable(),
  validatorCommissionOld: z.instanceof(Decimal).nullable(),
  tokenPriceNew: z.instanceof(Decimal).nullable(),
  tokenPriceOld: z.instanceof(Decimal).nullable(),
  active: z.boolean().nullable(),
  activeOld: z.boolean().nullable(),
  usdGrantedNew: z.instanceof(Decimal).nullable(),
  usdGrantedOld: z.instanceof(Decimal).nullable(),
  updateNote: z.string().nullable(),
  startDate: z.date().nullable(),
  endDate: z.date().nullable(),
  maxMonths: z.number().int().positive().nullable(),
  createdById: z.string().uuid(),
  delegationId: z.string().uuid().nullable(),
  grantId: z.string().uuid().nullable(),
  createdAt: z.date(),
  grantSwitchStaticRevenue: z.boolean().nullable(),
}) satisfies ZodSchema<DealUpdateEvent>;

export const dealUpdateEventCreateSchema = dealUpdateEventSchema
  .omit({
    id: true,
    createdAt: true,
    aprOld: true,
    validatorCommissionOld: true,
    tokenPriceOld: true,
    tokenAmountOld: true,
    activeOld: true,
    usdGrantedOld: true,
  })
  .extend({
    delegationId: z.string().uuid().nullable(),
    grantId: z.string().uuid().nullable(),
  })
  .refine(
    (data) => (data.delegationId !== null) !== (data.grantId !== null),
    "Either delegationId or grantId must be provided, but not both.",
  ); // satisfies ZodSchema<DealUpdateEventNew>;

export const dealUpdateEventUpdateSchema = dealUpdateEventSchema
  .partial()
  .extend({
    id: z.string().uuid(),
  })
  .omit({
    createdAt: true,
  }) satisfies ZodSchema<DealUpdateEventUpdate>;
