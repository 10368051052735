import { Decimal } from "decimal.js";
import { z } from "zod";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";

export type DealGrant = {
  id: string;
  tokenAmountSignDate: Decimal | null;
  tokenAmountCurrent: Decimal | null;
  staticDailyRevenue: boolean;
  tokenPriceSignDate: Decimal | null;
  tokenPriceCurrent: Decimal | null;
  usdGrantedSignDate: Decimal | null;
  usdGrantedCurrent: Decimal | null;
  tokenId: string | null;
  startDate: Date;
  endDate: Date;
  maxMonths: number | null;
  createdById: string;
  partnerDealId: string;
  grantType: "USD" | "TOKEN";
  active: boolean;
};

// Define base properties for DealGrantNew
type BaseDealGrantNew = Identity<
  Omit<
    DealGrant,
    | "id"
    | "usdGrantedSignDate"
    | "usdGrantedCurrent"
    | "tokenAmountSignDate"
    | "tokenId"
    | "tokenAmountCurrent"
    | "tokenPriceSignDate"
    | "tokenPriceCurrent"
    | "maxMonths"
    | "endDate"
    | "startDate"
    | "active"
  > & {
    startDate?: Date;
    staticDailyRevenue?: boolean;
    maxMonths?: number;
    endDate?: Date;
    active?: boolean;
  }
>;

// Define USD-based grant properties
export type UsdBasedGrantNew = BaseDealGrantNew & {
  grantType: "USD";
  usdGrantedSignDate: Decimal;
  usdGrantedCurrent?: Decimal;
};

// Define token-based grant properties
export type TokenBasedGrantNew = BaseDealGrantNew & {
  grantType: "TOKEN";
  tokenAmountSignDate: Decimal;
  tokenPriceSignDate: Decimal;
  tokenAmountCurrent?: Decimal;
  tokenPriceCurrent?: Decimal;
  tokenId: string;
};

export type DealGrantNew = (UsdBasedGrantNew & { grantType: "USD" }) | (TokenBasedGrantNew & { grantType: "TOKEN" });

export type DealGrantUpdate = Identity<Partial<Omit<DealGrant, "id" | "partnerDealId">> & { id: string }>;

// Base schema for DealGrant
const baseDealGrantSchema = z.object({
  id: z.string().uuid(),
  staticDailyRevenue: z.boolean(),
  startDate: z.date(),
  endDate: z.date(),
  maxMonths: z.number().int().positive().nullable(),
  createdById: z.string().uuid(),
  partnerDealId: z.string().uuid(),
});

// Schema for USD-based grant
const usdBasedGrantSchema = baseDealGrantSchema.extend({
  usdGrantedSignDate: z.instanceof(Decimal),
  usdGrantedCurrent: z.instanceof(Decimal),
  tokenAmountSignDate: z.instanceof(Decimal),
  tokenAmountCurrent: z.instanceof(Decimal),
  tokenPriceSignDate: z.instanceof(Decimal),
  tokenPriceCurrent: z.instanceof(Decimal),
  tokenId: z.string().uuid(),
  grantType: z.literal("USD"),
});

// Schema for token-based grant
const tokenBasedGrantSchema = baseDealGrantSchema.extend({
  tokenAmountSignDate: z.instanceof(Decimal),
  tokenAmountCurrent: z.instanceof(Decimal),
  tokenPriceSignDate: z.instanceof(Decimal),
  tokenPriceCurrent: z.instanceof(Decimal),
  usdGrantedSignDate: z.instanceof(Decimal),
  usdGrantedCurrent: z.instanceof(Decimal),
  grantType: z.literal("TOKEN"),
});

// Combined schema for DealGrant
export const dealGrantSchema = z.union([usdBasedGrantSchema, tokenBasedGrantSchema]);

// Schemas for creating a new DealGrant

export const dealGrantBaseSchema = z.object({
  grantType: z.enum(["USD", "TOKEN"]),
  createdById: z.string().uuid(),
  startDate: z.date().optional().default(new Date()),
  endDate: z.date().optional(),
  maxMonths: z.number().int().positive().optional(),
  staticDailyRevenue: z.boolean().optional().default(true),
  partnerDealId: z.string().uuid(),
  active: z.boolean().optional().default(true),
});

export const dealUsdGrantCreateSchema = dealGrantBaseSchema.extend({
  usdGrantedSignDate: z.instanceof(Decimal),
  usdGrantedCurrent: z.instanceof(Decimal).optional(),
  grantType: z.literal("USD"),
}) satisfies ZodSchema<UsdBasedGrantNew>;

export const dealTokenGrantCreateSchema = dealGrantBaseSchema.extend({
  tokenAmountSignDate: z.instanceof(Decimal),
  tokenPriceSignDate: z.instanceof(Decimal),
  tokenId: z.string().uuid(),
  tokenAmountCurrent: z.instanceof(Decimal).optional(),
  tokenPriceCurrent: z.instanceof(Decimal).optional(),
  grantType: z.literal("TOKEN"),
}) satisfies ZodSchema<TokenBasedGrantNew>;

export const dealGrantCreateSchema = z.discriminatedUnion("grantType", [
  dealUsdGrantCreateSchema,
  dealTokenGrantCreateSchema,
]);

export const dealGrantUpdateSchema = dealUsdGrantCreateSchema.merge(dealTokenGrantCreateSchema).partial().extend({
  id: z.string().uuid(),
}) satisfies ZodSchema<DealGrantUpdate>;
