import { Decimal } from "decimal.js";
import { z } from "zod";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";

export type DealDelegation = {
  id: string;
  startDate: Date;
  endDate: Date | null;
  maxMonths: number | null;
  tokenAmountSignDate: Decimal;
  tokenAmountCurrent: Decimal;
  tokenPriceSignDate: Decimal;
  tokenPriceCurrent: Decimal;
  tokenId: string;
  aprSignDate: Decimal;
  aprCurrent: Decimal;
  isAprLocked: boolean;
  validatorCommissionSignDate: Decimal;
  validatorCommissionCurrent: Decimal;
  createdById: string;
  partnerDealId: string;
};

export type DealDelegationNew = Identity<
  Omit<
    DealDelegation,
    | "id"
    | "startDate"
    | "aprCurrent"
    | "validatorCommissionCurrent"
    | "tokenAmountCurrent"
    | "tokenPriceCurrent"
    | "maxMonths"
    | "isAprLocked"
    | "endDate"
    | "active"
  > & {
    startDate?: Date;
    aprCurrent?: Decimal;
    validatorCommissionCurrent?: Decimal;
    tokenAmountCurrent?: Decimal;
    tokenPriceCurrent?: Decimal;
    maxMonths?: number;
    isAprLocked?: boolean;
    endDate?: Date;
    active?: boolean;
  }
>;
export type DealDelegationUpdate = Identity<Partial<Omit<DealDelegation, "id" | "partnerDealId">> & { id: string }>;

export const dealDelegationSchema = z.object({
  id: z.string().uuid(),
  startDate: z.date(),
  endDate: z.date().nullable().optional().default(null),
  maxMonths: z.number().int().positive().nullable(),
  tokenAmountSignDate: z.instanceof(Decimal),
  tokenAmountCurrent: z.instanceof(Decimal),
  tokenPriceSignDate: z.instanceof(Decimal),
  tokenPriceCurrent: z.instanceof(Decimal),
  tokenId: z.string().uuid(),
  aprSignDate: z.instanceof(Decimal),
  aprCurrent: z.instanceof(Decimal),
  isAprLocked: z.boolean(),
  validatorCommissionSignDate: z.instanceof(Decimal),
  validatorCommissionCurrent: z.instanceof(Decimal),
  createdById: z.string().uuid(),
  partnerDealId: z.string().uuid(),
  active: z.boolean().optional().default(true),
}) satisfies ZodSchema<DealDelegation>;

export const dealDelegationCreateSchema = dealDelegationSchema
  .omit({
    id: true,
  })
  .extend({
    aprCurrent: z.instanceof(Decimal).optional(),
    validatorCommissionCurrent: z.instanceof(Decimal).optional(),
    tokenAmountCurrent: z.instanceof(Decimal).optional(),
    tokenPriceCurrent: z.instanceof(Decimal).optional(),
    maxMonths: z.number().int().positive().optional(),
    isAprLocked: z.boolean().optional(),
    endDate: z.date().optional(),
  }) satisfies ZodSchema<DealDelegationNew>;

export const dealDelegationUpdateSchema = dealDelegationSchema
  .partial()
  .extend({
    id: z.string().uuid(),
  })
  .omit({
    partnerDealId: true,
  }) satisfies ZodSchema<DealDelegationUpdate>;
